import * as Sentry from "@sentry/react";
import logo from "@svg/Propely_Logo_Purple.svg";
import { Suspense, lazy } from "react";
import { hydrate } from "react-dom";
import { QueryClient } from "react-query";
import { ToastContainer } from "react-toastify";
import { initI18n } from "./libs/i18next/i18n";

if (!process.env.REACT_APP_RUNNING_LOCALLY && !process.env.CYPRESS) {
  Sentry.init({
    dsn: "https://48714bb28597254456c2db3d19ec0227@o4507033354305536.ingest.de.sentry.io/4507231123079248",
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/propely\.no/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.REACT_APP_ENVIRONMENT,
  });
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

const main = async () => {
  // Load translations first
  await initI18n({
    loadPath: "/i18n/{{lng}}/{{ns}}.json",
    debug: false,
    returnNull: false,
  });

  // Import the components only AFTER the translations are loaded. Importing components before the
  // translations are ready will result in missing strings on the UI
  const App = lazy(() => import("./App"));

  const { CompanyImageView } = await import("./components/deprecated/CompanyImageView/CompanyImageView");
  const { Popup } = await import("./components/deprecated/Popup/Popup");
  const { PopupRootContainer } = await import("./components/deprecated/PopupRoot/PopupRoot");

  hydrate(
    <Suspense
      fallback={
        <div id="loading-fullscreen">
          <img src={logo} />
          <div className="loading-indicator" />
        </div>
      }
    >
      <App />
    </Suspense>,
    document.getElementById("root")
  );

  hydrate(<PopupRootContainer />, document.getElementById(Popup.popup_div_id));
  hydrate(<ToastContainer />, document.getElementById("toast-container"));
};

main();
